<template>
    <div class="card card-top-line">
        <div class="header d-flex align-items-center justify-content-between">
            <h2>{{businessType}} Contact Details</h2>
            <i class="fa fa-edit font-20" @click="editDetails()"></i>
        </div>
        <div class="body" v-if="!businessContact.readonlyMode">
            <div class="account-form">
                <ValidationObserver v-slot="{ passes }">
                    <form @submit.prevent="passes(businessContactSubmit)" id="basic-info">
                        <ValidationProvider name="Address Line 1" rules="required" v-slot="{ errors}" v-if="!businessContact.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !businessContact.addressLine1,'success':businessContact.addressLine1}">
                                <span class="float-label">
                                    <input type="text" v-model="businessContact.addressLine1" class="form-control" id="address1" placeholder="Address Line 1*" :disabled="businessContact.readonlyMode"/>
                                    <label for="address1" class="control-label">Address Line 1*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessContact.addressLine1 && !businessContact.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessContact.addressLine1 && !businessContact.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <div class="form-group" v-if="!businessContact.readonlyMode">
                            <span class="float-label">
                                <input type="text" v-model="businessContact.addressLine2" class="form-control" id="address2" placeholder="Address Line 2" :disabled="businessContact.readonlyMode"/>
                                <label for="address2" class="control-label">Address Line 2</label>
                                <div class="line"></div>
                            </span>
                        </div>
                        <ValidationProvider name="town" rules="required" v-slot="{ errors}" v-if="!businessContact.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !businessContact.town,'success':businessContact.town}">
                                <span class="float-label">
                                    <input type="text" v-model="businessContact.town" class="form-control" id="town" placeholder="Town*" :disabled="businessContact.readonlyMode"/>
                                    <label for="town" class="control-label">Town*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessContact.town && !businessContact.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessContact.town && !businessContact.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="city" rules="required" v-slot="{ errors}" v-if="!businessContact.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !businessContact.city,'success':businessContact.city}">
                                <span class="float-label">
                                    <input type="text" v-model="businessContact.city" class="form-control" id="city" placeholder="City*" :disabled="businessContact.readonlyMode"/>
                                    <label for="city" class="control-label">City*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessContact.city && !businessContact.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessContact.city && !businessContact.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="sprc" rules="required" v-slot="{ errors}" v-if="!businessContact.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !businessContact.sprc,'success':businessContact.sprc}">
                                <span class="float-label">
                                    <input type="text" v-model="businessContact.sprc" class="form-control" id="sprc" placeholder="State/Province/Region/County*" :disabled="businessContact.readonlyMode"/>
                                    <label for="sprc" class="control-label">State/Province/Region/County*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessContact.sprc && !businessContact.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessContact.sprc && !businessContact.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="postCode" rules="required" v-slot="{ errors}" v-if="!businessContact.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !businessContact.postCode,'success':businessContact.postCode}">
                                <span class="float-label">
                                    <input type="text" v-model="businessContact.postCode" class="form-control" id="postCode" placeholder="Post/Zip Code*" :disabled="businessContact.readonlyMode"/>
                                    <label for="postCode" class="control-label">Post/Zip Code*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessContact.postCode && !businessContact.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessContact.postCode && !businessContact.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':businessContact.readonlyMode}" v-if="!businessContact.readonlyMode">
                            <p class="mb-0 mr-3 custom-switch-text">Is the registered {{businessType}} address the same as where you normally operate your {{businessType}}? <span class="text-danger">*</span></p>
                            <div class="custom-switch success-handle">
                                <input type="checkbox" class="custom-switch-input" id="tradingAddress" v-model="businessContact.tradingAddress" :disabled="businessContact.readonlyMode">
                                <label class="custom-switch-label" for="tradingAddress">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <ValidationProvider name="tradingAddress1" :rules="businessContact.tradingAddress ? 'required' : ''" v-slot="{ errors}"  v-if="businessContact.tradingAddress && !businessContact.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !businessContact.tradingAddressInput1,'success':businessContact.tradingAddressInput1}">
                                <span class="float-label">
                                    <input type="text" v-model="businessContact.tradingAddressInput1" class="form-control" id="tradingaddress1" placeholder="Address Line 1*" :disabled="businessContact.readonlyMode"/>
                                    <label for="tradingaddress1" class="control-label">Address Line 1*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessContact.tradingAddressInput1 && !businessContact.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessContact.tradingAddressInput1 && !businessContact.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <div class="form-group" v-if="businessContact.tradingAddress && !businessContact.readonlyMode">
                            <span class="float-label">
                                <input type="text" v-model="businessContact.tradingAddressInput2" class="form-control" id="tradingaddress2" placeholder="Address Line 2"/>
                                <label for="tradingaddress2" class="control-label">Address Line 2</label>
                                <div class="line"></div>
                            </span>
                        </div>
                        <ValidationProvider name="tradingTown" :rules="businessContact.tradingAddress ? 'required' : ''" v-slot="{ errors}" v-if="businessContact.tradingAddress && !businessContact.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !businessContact.tradingTown,'success':businessContact.tradingTown}">
                                <span class="float-label">
                                    <input type="text" v-model="businessContact.tradingTown" class="form-control" id="tradingTown" placeholder="Town*" :disabled="businessContact.readonlyMode"/>
                                    <label for="tradingTown" class="control-label">Town*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessContact.tradingTown && !businessContact.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessContact.tradingTown && !businessContact.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="tradingCity" :rules="businessContact.tradingAddress ? 'required' : ''" v-slot="{ errors}" v-if="businessContact.tradingAddress && !businessContact.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !businessContact.tradingCity,'success':businessContact.tradingCity}">
                                <span class="float-label">
                                    <input type="text" v-model="businessContact.tradingCity" class="form-control" id="tradingCity" placeholder="City*" :disabled="businessContact.readonlyMode"/>
                                    <label for="tradingCity" class="control-label">City*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessContact.tradingCity && !businessContact.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessContact.tradingCity && !businessContact.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="tsprc" :rules="businessContact.tradingAddress ? 'required' : ''" v-slot="{ errors}" v-if="businessContact.tradingAddress && !businessContact.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !businessContact.tradingSprc,'success':businessContact.tradingSprc}">
                                <span class="float-label">
                                    <input type="text" v-model="businessContact.tradingSprc" class="form-control" id="tsprc" placeholder="State/Province/Region/County*" :disabled="businessContact.readonlyMode"/>
                                    <label for="tsprc" class="control-label">State/Province/Region/County*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessContact.tradingSprc && !businessContact.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessContact.tradingSprc && !businessContact.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="tradingpostCode" :rules="businessContact.tradingAddress ? 'required' : ''" v-slot="{ errors}" v-if="businessContact.tradingAddress && !businessContact.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !businessContact.tradingPostCode,'success':businessContact.tradingPostCode}">
                                <span class="float-label">
                                    <input type="text" v-model="businessContact.tradingPostCode" class="form-control" id="tradingpostCode" placeholder="Post/Zip Code*" :disabled="businessContact.readonlyMode"/>
                                    <label for="tradingpostCode" class="control-label">Post/Zip Code*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessContact.tradingPostCode  && !businessContact.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessContact.tradingPostCode  && !businessContact.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Mobile_Number" rules="required" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && !businessContact.mobileNumber,'success':businessContact.mobileNumber}">
                                <span class="float-label">
                                    <input type="text" v-model="businessContact.mobileNumber" class="form-control" id="mobile-number" placeholder="Mobile Number*" :disabled="businessContact.readonlyMode"/>
                                    <label for="mobile-number" class="control-label">Mobile Number*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessContact.mobileNumber && !businessContact.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessContact.mobileNumber && !businessContact.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="email_address" rules="required|email" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && !businessContact.email || errors[0],'success':businessContact.email && !errors[0]}">
                                <span class="float-label">
                                    <input type="email" v-model="businessContact.email" class="form-control" name="email" id="email-address" placeholder="Email*" :disabled="businessContact.readonlyMode">
                                    <label for="email-address" class="control-label">Email*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessContact.email || errors[0] && !businessContact.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessContact.email && !errors[0] && !businessContact.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':businessContact.readonlyMode}" v-if="!businessContact.readonlyMode">
                            <p class="mb-0 mr-3 custom-switch-text">Do you have a website? <span class="text-danger">*</span></p>
                            <div class="custom-switch success-handle">
                                <input type="checkbox" class="custom-switch-input" id="websiteURLdis" v-model="businessContact.websiteURLDisplay" :disabled="businessContact.readonlyMode">
                                <label class="custom-switch-label" for="websiteURLdis">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <ValidationProvider name="Website_URL" :rules="businessContact.websiteURLDisplay ? 'required' : ''" v-slot="{ errors}" v-if="businessContact.websiteURLDisplay && !businessContact.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !businessContact.websiteURL || errors[0],'success':businessContact.websiteURL && !errors[0]}">
                                <span class="float-label">
                                    <input type="text" v-model="businessContact.websiteURL" class="form-control" name="websiteURL" id="website_URL" placeholder="Website URL*" :disabled="businessContact.readonlyMode">
                                    <label for="websiteURL" class="control-label">Website URL*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !businessContact.websiteURL || errors[0] && !businessContact.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="businessContact.websiteURL && !errors[0] && !businessContact.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <div v-if="!businessContact.readonlyMode" class="justify-content-center d-flex">
                            <b-button size="lg" @click="businessContactSubmit()" class="btn-mw">Cancel</b-button>
                            <b-button type="submit" variant="primary ml-1 btn-mw" size="lg">Update</b-button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";

export default {
    name: 'BusinessContactDetails-Profile',
    components:{
        ValidationObserver,
        ValidationProvider,
    },
    props: {
        businessType: {
            type: String,
            default: function () {
                return 'Business'
            }
        },
    },
    data(){
        return{
           //Business Contact Details
            businessContact:{
                readonlyMode: true,
                addressLine1:'2 St Dunstans Street,United Kingdom',
                addressLine2:'99 Warner Close',
                town:'Warlingham',
                city:'Hastingwood',
                sprc:'United Kingdom',
                postCode:'CR6 6AL',
                tradingAddress:false,
                tradingAddressInput1:'',
                tradingAddressInput2:'',
                tradingTown:'',
                tradingCity:'',
                tradingSprc:'',
                tradingPostCode:'',
                mobileNumber:'070 2009 9424',
                email:'alizee.info@yourdomain.com',
                websiteURLDisplay:false,
                websiteURL:'',
            }
        }
    },methods:{
        editDetails(){
            this.businessContact.readonlyMode = false
        },

        //Business Contact Details Data
        businessContactSubmit(){
                if(this.businessContact.addressLine1 ==''){
                    this.businessContact.addressLine1 = '2 St Dunstans Street,United Kingdom'
                }else if(this.businessContact.addressLine2  == ''){
                    this.businessContact.addressLine2 = '99 Warner Close'
                }else if(this.businessContact.town == ''){
                    this.businessContact.town ='Warlingham'
                }else if(this.businessContact.city == ''){
                    this.businessContact.city ='Hastingwood'
                }else if(this.businessContact.sprc == ''){
                    this.businessContact.sprc ='United Kingdom'
                }else if(this.businessContact.postCode == ''){
                    this.businessContact.postCode ='CR6 6AL'
                }else if(this.businessContact.tradingAddressInput1 == ''){
                    this.businessContact.tradingAddressInput1 ='2 St Dunstans Street,United Kingdom'
                }else if(this.businessContact.tradingAddressInput2 == ''){
                    this.businessContact.tradingAddressInput2 = '101 Warner Close'
                }else if(this.businessContact.tradingTown == ''){
                    this.businessContact.tradingTown = 'Warlingham'
                }else if(this.businessContact.tradingCity == ''){
                    this.businessContact.tradingCity ='Hastingwood'
                }else if(this.businessContact.tradingSprc == ''){
                    this.businessContact.tradingSprc = 'United Kingdom'
                }else if(this.businessContact.tradingPostCode == ''){
                    this.businessContact.tradingPostCode = 'CR6 6AL'
                }else if(this.businessContact.mobileNumber == ''){
                    this.businessContact.mobileNumber = '070 2009 9424'
                }else if(this.businessContact.email == ''){
                    this.businessContact.email = 'alizee.info@yourdomain.com'
                }else if(this.businessContact.websiteURL == ''){
                    this.businessContact.websiteURL = 'www.goggle.com'
                }
                this.businessContact.readonlyMode = true
        },
    }
}
</script>
